@import "../../../../../Assets/Styles/variables";

.section-four {
  height: 100%;
  background-color: $white;
  .container {
    max-width: 80%;
    @media screen and (max-width: 767px) {
      max-width: 90%;
     }
  }
  .title {
    font-size: $xl-font;
    font-weight: 600;
    line-height: 1.24;
    margin-right: 0;
    font-family: Pretendard-SemiBold;
  }
  .history {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: $white;
    .boxHistory {
      display: flex;
      align-items: center;
      width: 100%;
      flex-direction: column;
      .boxContent {
        width: 100%;
     
        &_item {
          text-align: left;
          outline: none;
          display: flex;
          align-items: baseline;
          flex-direction: row;
          width: 100%;
          gap: 0 20px;
          padding-block: 50px;
          border-bottom: 1px solid $border-text;
          .year {
            font-size: 36.5px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.11;
            text-align: center;
            margin-bottom: 3rem;
            font-family: $montserrat;
          }
          .box {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: row;
            margin-bottom: 20px;
            font-family: $pretendardVariable;
            p {
              margin-bottom: 0;
            }
            &_text {
              font-size: 17.5px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 2.31;
              color: $color-text;
            }
            &_bold {
              font-size: 17.5px;
              font-weight: 600;
              font-stretch: normal;
              font-style: normal;
              line-height: 2.31;
              margin-right: 15px;
            }
          }
        }
      }
    }
  }
  @media screen and (min-width: 992px) {
    .boxHistory {
      flex-direction: row !important;
      align-items: baseline !important;
    }
    .boxContent {
      display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        &_item {
          gap: 0 clamp(40px, 6vw, 200px) !important;
        }
    }
    .year {
      text-align: left;
      margin-bottom: 0;
    }
    .box {
      justify-content: unset !important;
    }
    .title {
      width: 30%;
      margin-right: clamp(40px, 6vw, 220px);
    }
  }
  @media screen and (max-width: 767px) {
    .container {
      padding-top: 85px;
    }
    .title {
      font-size: 34px;

  }
  .history .boxHistory .boxContent_item {
    border-bottom: none;
    padding-block: 20px;
    .box {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      .box_bold {
        font-family: Pretendard-SemiBold;
      }
      .box_text {
        color: #999999;
        line-height: 1.4;
    }
  }
}
  }
}

@import "../../../../../Assets/Styles/variables";

.page-three {
  height: 100vh;
  user-select: none;
  background-size: cover;
  overflow: hidden;
  background-color: $bg3-color;
  width: 100%;
  &-container {
    height: 100%;
  }
  .slick-track {
    height: 100vh;
    cursor: grab;
  }
  .slick-slider {
    margin-left: 15%;
    @media (max-width: 1199px) {
        margin-left: 0;
    }
  }
  .slick-initialized .slick-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    &:last-child {
      margin-right: 0;
    }
    &:hover {
      &:hover {
        &:nth-child(1) {
          .card-btn {
            span {
              color: $box1-section3;
              transition: 0.2s all cubic-bezier(0.075, 0.82, 0.165, 1);
            }
          }
        }
        &:nth-child(2) {
          .card-btn {
            span {
              color: $box2-section3;
              transition: 0.2s all cubic-bezier(0.075, 0.82, 0.165, 1);
            }
          }
        }
        &:nth-child(3) {
          .card-btn {
            span {
              color: $box3-section3 !important;
              transition: 0.2s all cubic-bezier(0.075, 0.82, 0.165, 1);
            }
          }
        }
        &:nth-child(4) {
          .card-btn {
            span {
              color: $box4-section3 !important;
              transition: 0.2s all cubic-bezier(0.075, 0.82, 0.165, 1);
            }
          }
        }
      }
    }
  }
  .slick-dots {
    top: 90% !important;
    transform: translateY(-50%) !important;
  }
  @media screen and (max-width: 767px) {
    padding-inline: 20px;
    padding-top: 85px;
    .slick-slide {
      height: 80% !important;
    }
  }
}

@import '../../../Assets/Styles/variables';

.contact {
  width: 100%;
  background-color: $bg-color;

  &-container {
    margin: auto;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    max-width: 1360px;
  }

  &-title {
    margin-bottom: 10px;
    font-size: 4rem;
    font-weight: 700;
    color: $color-text;
    position: relative;
    text-align: center;
    padding: 90px 0;
  }

  &-main {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex: 1;
    transition: all 0.2s ease;
  }

  &-map {
    flex: 1 1 70%;

    iframe {
      width: 100%;
      height: 100%;
    }
  }

  &-detail {
    overflow: hidden;
    flex: 1 1 30%;
    background-color: #673dc7;
    color: $color-text;
    display: flex;
    flex-direction: column;
    padding-top: 4vw;
    padding-left: 4vw;
    padding-bottom: 30px;
    padding-right: 10px;

    // padding-top: 12.8%;
    p {
      margin: 0;
    }

    .bottom {
      margin-top: auto;

      p {
        font-size: 1.8rem;
        font-weight: 300;
        line-height: 1.3;
      }
    }

    .top {
      &-items:not(:last-child) {
        margin-bottom: 80px;
      }

      .title {
        font-size: 3.4rem;
        font-weight: 700;
      }

      .link {
        display: inline-block;
        font-weight: 300;
        color: inherit;
        font-size: 1.6rem;

        &:hover {
          color: $white;
        }
      }

      @media screen and (max-width: 700px) {
        .link {
          font-size: 1.4rem;
          letter-spacing: -1px
        }

      }
    }
  }



  // @media screen and (max-width: 1311px) {
  //   &-detail {
  //     .top {
  //       padding-top: 18.8%;
  //       padding-left: 18.8%;
  //     }
  //     .bottom {
  //       padding-bottom: 18.8%;
  //       padding-right: 18.8%;
  //     }
  //   }
  // }
  // @media screen and (max-width: 930px) {
  //   &-main {
  //     flex-direction: column;
  //   }
  // }
  // @media screen and (max-width: 823px) {
  //   &-detail {
  //     .bottom {
  //       padding-bottom: 10%;
  //     }
  //   }
  // }
}
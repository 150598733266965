// Montserrat
@import "./variables.scss";
@import "./mixins";

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  -webkit-tab-highlight-color: transparent;
  user-select: none;
  // @include family--montserrat;
}

body {
  font-family: $pretendardVariable;
  #fp-nav {
    z-index: unset;
    @media screen and (max-width: 767px) {
      &.fp-right {
        right: 0 ;
      }
    }
  }
  .fp-table {
    min-height: 700px;
    @media screen and (max-width: 767px) {
      min-height: 1px;
    }
  }

  #fp-nav ul li a.active span {
    background: $bg-lang-active !important;
    height: 8px !important;
    width: 8px !important;
    margin: -1px 0 0 -2px;

    &::before {
      content: "";
      background: $bg-lang-active !important;
      transition: all 0.5s;
      height: 8px !important;
      width: 8px !important;
    }
  }

  #fp-nav ul li:hover a span {
    background: $bg-lang-active !important;
    width: 8px !important;
    height: 8px !important;
    margin: 0 !important;
    &::before {
      content: "";
      background: $bg-lang-active !important;
      transition: all 0.5s;

    }
  }

  #fp-nav ul li a span {
    width: 8px;
    height: 8px;
    background: $icon-slide;
    border-radius: 8px;
    transform: translateX(-100%);
    position: absolute;
    left: 0;
    &.fp-sr-only {
      display: none;
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      z-index: 0;
      width: 8px;
      height: 8px;
      border-radius: 8px;
      transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
    }
  }

  &.fp-viewing-secondPage,
  &.fp-viewing-thirdPage,
  &.fp-viewing-fivePage {
    #fp-nav ul li:hover a span {
      background: $bg-lang-active !important;
    }
  }

  &.fp-viewing-secondPage,
  &.fp-viewing-thirdPage,
  &.fp-viewing-fivePage {
    #fp-nav ul li a.active span {
      background: $bg-lang-active !important;
    }
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $black;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
}

video {
  max-width: 100%;
}

img {
  max-width: 100%;
}

.p {
  color: $color-text;
  line-height: $lh-p;
  font-size: $text-font;
  font-weight: normal;
}

.container {
  width: 100%;
  max-width: 70%;
  margin: 0 auto;

  @include tablet {
    width: 90%;
  }
}

.container-fluid {
  width: 1860px;
  margin: 0 auto;

  @include laptop {
    width: 1460px;
  }
}

@import '../../../../../Assets/Styles/variables';
@import '../../../../../Assets/Styles/mixins';

.section-one {
  @include family--pretendard;
  height: 100%;
  &-container {
    margin: 0 5%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: 767px) {
      padding-top: 85px;
      flex-direction: column-reverse;
    }
  }
  &-bg {
    display: none;
    @media screen and (max-width: 767px) {
      display: block;
      img {
        width: 90%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  &-main {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    height: 80%;
  }
  &-btn {
    padding: 16px 36px 17px;
    background-color: $black;
    border-radius: 25px;
    color: $white;
    line-height: 1.62;
    font-size: $text-font;
    margin-bottom: 46px;
    &:hover {
      color: white;
    }
  }
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  &-text {
    margin-top: 57px;
  }

  &-title {
    color: $black;
    margin-bottom: 0;
    font-size: clamp(48px, 4vw, 64px);
    font-weight: 600;
    line-height: 1.24;
    font-family: Pretendard-SemiBold;
  }
  @media screen and (max-width: 576px) {
    &-header {
      justify-content: flex-start;
      .section-one-link {
        margin-left: 50px;
      }
    }
  }
  @media screen and (min-width: 991px) {
    &-container {
      gap: 0 3rem;
    }
    &-bg {
      display: block;
    }
    &-main {
      width: 60%;
    }
  }
  @media screen and (min-width: 1400px) {
    &-main {
      width: 32%;
    }
  }
  @media screen and (max-width: 767px) {
    &-main {
      padding-top: 10px;
      .section-one-title {
        font-size: 31px;
      }
      .section-one-text  {
          font-size: 16px;
       margin-top: 20px;
      }
    }
    &-btn {
      order: 2;
      margin-bottom: 15px;
      margin-top: 20px;
    }
  }
}

@import "../../../../../Assets/Styles/variables";
@import "../../../../../Assets/Styles/mixins";

.section-five {

  height: 100%;
  background-color: $bg2-color;
  padding: 100px;
  @include flexCenter();
  font-family: $montserrat;


  &-container {
    @include flexCenter();
    flex-direction: column;

  }

  &-main {
    @include flexCenter();
    align-items: flex-end;
    gap: 0 390px;
  }

  &-img {
    margin-bottom: 120px;
    width: 80%;
    max-width: 1196px;
  }

  &-link {
    font-size: clamp(16px, 4vw, 20px);
    color: $black;
    line-height: 1.24;
    margin-bottom: 0;

    &-social {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
    }
  }

  &-text {
    font-size: clamp(30px, 4vw, $lg-font);
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.34;
    margin-bottom: 0;
  }

  &-wrapper {
    @include flexCenter();
    gap: 0 120px;

    &-item {
      @include flexCenter();
      align-items: flex-start;

      &-text {
        font-size: clamp(16px, 4vw, 20px);
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.34;
        margin-bottom: 0;
      }

      flex-direction: column;

      a {
        font-size: clamp(16px, 4vw, 20px);
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.24;
        color: $black;
      }
    }
  }

  @media screen and (max-width: 1599px) {
    padding: 20px;

    &-main {
      gap: 0 clamp(100px, 3vw, 300px);
    }
  }

  @media screen and (max-width: 1199px) {
    &-main {
      flex-direction: row;
      justify-content: start;
      align-items: flex-end;
      gap: 30px;
    }
  }

  @media screen and (max-width: 991px) {

    &-container {
      align-items: flex-start;
    }

    &-wrapper,
    &-main {
      gap: 0 clamp(20px, 4vw, 80px);
      align-items: start;
      flex-direction: column;
    }

    &-img {
      width: 100%;
    }
  }

  @media screen and (max-width: 767px) {
    &-img {
      margin-bottom: 0;
    }

    &-elm {
      margin-bottom: 20px;
    }
  }

  @media screen and (max-width: 767px) {
    padding-top: 85px;

    .section-five-elm {
      margin-bottom: 5px;
    }

    .section-five-text {
      font-size: 21px;
      line-height: 1.4;
    }

    .section-five-link {
      padding-top: 7px;
      font-size: 14px;
    }
  }
}
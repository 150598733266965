@import "./fonts";

$family: (
  primary: "Montserrat",
  second: "PretendardVariable",
);

$montserrat: "Montserrat", sans-serif;
$pretendardVariable: "PretendardVariable";

$header-height: 72px;

$color: (
  // theme
  main: #673dc7,
  //color
  // required
  b: #000,
  w: #fff,
  x: #f6f6f6,
  b-op: rgba(0, 0, 0, 0.8),
  // notification
);

$bg-lang-active: #f1589c;
$white: #fff;
$black: #222222;
$bg-color: #ffffff;
$bg2-color: #f8fafc;
$bg3-color: #fad9ec;
$textBtn-color: #ffaa00;
$text2Btn-color: #e86db0;

$color-text: #999999;
$border-text: rgba(153, 153, 153, 0.318);
$icon-slide: #d9d9d9;
$yellow-color: #fff0ab;

$box1-section3: #8475ca;
$box2-section3: $textBtn-color;
$box3-section3: #658eea;
$box4-section3: #41c1cc;

$xl-font: 64px;
$lg-font: 34px;
$text-font: 18px;
$head-font: 21px;

$lh-p: 1.62;

$mobile-s: 350px;
$mobile: 415px;
$tablet: 768px;
$tablet-s: 600px;
$large: 1024px;
$desktop: 1400px;
$laptop: 1660px;
///////////////////// PAGE ////////////////////////////

@import "../../../Assets/Styles/index.scss";

.header {
  position: fixed;
  top: 40px;
  right: 0;
  left: 0;
  margin: 0 5%;
  z-index: 5;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: $montserrat;
  @media screen and (max-width: 767px) {
    top: 0px;
  }

  &_title {
    width: 115px;
    line-height: 3.7;
    font-size: $head-font;
    font-weight: bold;
    cursor: pointer;

    a {
      color: $black;
    }
  }

  &_box {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    &_menu_mobile-tonggle {
      cursor: pointer;
      position: relative;
      z-index: 300;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      &.active {
        span {
          background-color: white;
          transform: rotate(45deg) translate(2px, 6px);

          &:nth-child(2) {
            transform: rotate(-45deg) translate(0px, -5px);
          }

          &:nth-child(3) {
            opacity: 0;
          }
        }
      }

      span {
        display: block;
        width: 40px;
        height: 2px;
        margin-top: 7px;
        position: relative;
        background: $black;
        z-index: 0.3;
        transition: all 0.5s;
      }
    }

    &_lang {
      display: flex;
      cursor: pointer;

      .lang {
        font-family: Montserrat;
        font-size: 17.5px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: right;
        color: $black;
        margin-left: 10px;
        // margin-top: 5px;

        &.active {
          color: $bg-lang-active;
        }

        @media screen and (max-width: 415px) {
          font-size: 13px;
          margin-top: 10px;
          margin-left: 5px;
        }
      }
    }

    // child Menu

    &_menu {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      width: 450px;
      background-color: #000;
      z-index: 200;
      padding: 150px 50px 50px 32px;
      transform: translateX(100%);
      transition: all 0.3s;
      z-index: 10;

      @media screen and (max-width: 415px) {
        width: 250px;
      }

      &.active {
        display: block;
        transform: translateX(0);
      }

      @keyframes showMenu {
        from {
          transform: translateX(100%);
        }

        to {
          transform: translateX(0);
        }
      }

      &_mobile-icon-close {
       cursor: pointer;

        position: relative;
        z-index: 99;

        .icon {
          width: 40px;
          padding: 20px 0;

          span {
            display: block;
            width: 40px;
            height: 1px;
            position: relative;
            background: $black;
            z-index: 1;
            transform: rotate(135deg);

            &:nth-child(2) {
              transform: rotate(45deg);
            }
          }
        }

        .lang {
          color: #9c9c9c;
          // height: 30px;
          border: none;
          cursor: pointer;
          font-size: 17.5px;
          // font-weight: 700;
          text-align: center;
          box-sizing: border-box;
          // margin: 5px;

          &.active {
            color:#fff !important;
          }
        }
      }

      .menu {
        width: 100%;
        display: flex;
        flex-direction: column;

        a {
          font-size: 35px;
          color: #4d4d4d;
          transition: all 0.5s;

          //   font-family: $font;
          &.active {
            color: #fff;
          }

          &:hover {
            color: #fff;
          }

          @media screen and (max-width: 415px) {
            font-size: 25px;
          }
        }
      }

      .infoWrapper {
        position: absolute;
        width: 80%;
        bottom: 60px;
        border-top: 1px solid #4d4d4d;
        font-size: 12px;
        color: #fff;
        background: #000;

        p {
          margin: 0;
        }

        @include laptop {
          bottom: 30px;
        }

        @include mobile-s {
          height: 200px;
          overflow-y: auto;

          &::-webkit-scrollbar-track {
            background-color: transparent;
          }

          &::-webkit-scrollbar {
            width: 2px;
            background-color: transparent;
          }

          &::-webkit-scrollbar-thumb {
            background-color: #fff;
          }
        }

        #adress {
          padding-top: 40px;
          padding-bottom: 20px;
          width: 80%;
          font-family: "Montserrat", "Noto Sans KR", sans-serif;
        }

        .link {
          color: #fff;
          text-decoration: none;

          a {
            color: #aeaeae;

            &:hover {
              color: #fff;
            }
          }
        }
      }
      .disabled-link {
        pointer-events: none;
      }
    }
  }
}

// .arrow {
//   position: fixed;
//   z-index: 999;
//   bottom: 60px;
//   left: 60px;
//   cursor: pointer;
//   font-size: 8rem;
//   color: $black;
//   display: none;
// }
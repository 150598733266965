@import "../../../../../../Assets/Styles/variables";
@import "../../../../../../Assets/Styles/mixins";

.card-container {
  p {
    margin-top: 0;
    margin-bottom: 0em;
  }

  height: 708px;
  width: 478px;
  max-width: 480px;
  padding-block: 58px;
  @include family--montserrat;
  transform-origin: center;
  transition: all 0.5 ease;
  background-position: center;
  background-repeat: no-repeat;
  padding-inline: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  flex-direction: column;

  .card-title {
    transition: all 0.5s;
    font-size: clamp(22px, 3vw, 30px);
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.58;
  }

  .card-img {
    height: 279px;
  }

  .card-head {
    font-size: 40.5px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    height: 100%;
    transition: 0.5s all cubic-bezier(0.075, 0.82, 0.165, 1);
    @media screen and (max-width: 767px) {
      font-size: 25.5px;
    }
  }

  .card-desc {
    font-size: 17.5px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    transition: 0.5s all cubic-bezier(0.075, 0.82, 0.165, 1);
    font-family: $pretendardVariable;
    min-height: 85px;
    @media screen and (max-width: 767px) {
      font-size: 15.5px;
      min-height: 5px;
    }
  }

  .card-btn {
    margin: 39px 8px 0 0;

    span {

      font-size: clamp(14px, 2vw, $text-font);
      padding: 13px 25px;
      border-radius: 20px;
      font-family: Pretendard-SemiBold;
      font-weight: 600;
      line-height: 1.62;
    }

    span:first-child {
      background-color: $yellow-color;
      font-stretch: normal;
      font-style: normal;
      color: $textBtn-color;
      margin-right: 8px;
      transition: 0.5s all cubic-bezier(0.075, 0.82, 0.165, 1);

    }

    span:last-child {
      background-color: $bg3-color;

      transition: 0.5s all cubic-bezier(0.075, 0.82, 0.165, 1);
      color: $text2Btn-color;
    }

    &-one {
      background-color: #ededed !important;
      color: $color-text !important;
    }
  }

  &:hover {

    .card-title,
    .card-desc {
      opacity: 0;
      transition: 0.5s all cubic-bezier(0.075, 0.82, 0.165, 1);
    }

    .card-head {
      color: $white;
      transition: 0.5s all cubic-bezier(0.075, 0.82, 0.165, 1);
    }

    .card-btn {
      span {
        background-color: $white;
        transition: 0.2s all cubic-bezier(0.075, 0.82, 0.165, 1);
      }
    }
  }

  @media (max-width: 767px) {
    width: auto;
    padding-inline: 12px;
    height: 500px;
    padding-block: 20px;
    // height: 80%;
    
  }
}
@import "../../../../../Assets/Styles/variables";

.section-two {
  position: relative;
  height: 100%;
  overflow: hidden;
  background-color: $bg2-color;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 60px 0;
  font-family: $pretendardVariable;
  @media screen and (max-width: 787px) {
    gap: 10px 0;
    padding-top: 95px;
  }
  &-slogan {
    h1 {
      font-family: Pretendard-SemiBold;
      font-size: clamp(40px, 6vw, $xl-font);
      font-weight: 600;
      line-height: 1.24;
      text-align: center;
      max-width: 100%;
      width: 55%;
      max-height: 160px;
      margin: auto;
      overflow: hidden;
      @media screen and (max-width: 787px) {
        font-size: 22px;
        padding-top: 10px;
       
      }
    }
  }
  &-content {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    @media screen and (max-width: 787px) {
      // gap: 30px;
      padding: 30px 0;
    }
  }
  &-item {
    img {
      margin: auto;
    }
    p {
      margin-top: 36px;
      text-align: center;
      font-size: 17.5px;
      font-weight: 600;
      line-height: 2.71;
      text-transform: uppercase;
      margin-bottom: 0;
      font-family: $montserrat;
      @media screen and (max-width: 787px) {
        margin-top: 12px;
        line-height: 1.2;
        font-size: 12.5px;
      }
    }
  }
  &-desc {
    text-align: center;
    line-height: 1.56;
    color: $color-text;
    font-size: $text-font;
    max-width: 830px;
    @media screen and (max-width: 767px) {
      font-size: 15px;
      padding-top: 20px;
    }
  }
  @media screen and (max-width: 991px) {
    padding: 20px;
  }

  @media screen and (max-width: 767px) {
    display: block;
    padding-top: 85px;
    &-content {
      display: block;
    }
    .slick-dots {
      bottom: -18px !important;
    }
  }
}

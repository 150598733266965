@font-face {
  font-family: "PretendardVariable";
  font-style: normal;
  font-weight: 600;
  src: url("../Font/Pretendard-Regular.otf");
}

@font-face {
  font-family: "Pretendard-SemiBold";
  font-style: normal;
  font-weight: 600;
  src: url("../Font/Pretendard-SemiBold.otf");
}
  
  @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');
  
  
